import axios from "axios";
import { BASE_URL, PRESENSE_SERVER_URL } from "../Utils/GlobalVariable";
import setAuthToken from "../Utils/setAuthToken";

export const getTotalWalletAmountApi = async (userId) => {
  let response = await axios.get(
    `${BASE_URL}/users/${userId}/totalWalletAmount`
  );
  return response;
};

export const getLiveGiftsApi = async () => {
  let response = await axios.get(`${BASE_URL}/gifts/getAll`);
  return response;
};

export const sendLiveGiftsApi = async (data) => {
  let response = await axios.post(`${BASE_URL}/gifts/send`, {
    ...data,
  });
  return response;
};

export const getLiveStreamDataApi = async (eventId) => {
  let response = await axios.get(
    `${BASE_URL}/gifts/totalCollection/${eventId}`
  );
  return response;
};

// infuluencer status api

export const getInfluencerStatusApi = async (influencerId) => {
  let response = await axios.get(
    `${BASE_URL}/influencer/${influencerId}/price-details`
  );
  return response;
};

export const confirmCallUserApi = async (influencerId, userId, currency) => {
  let response = await axios.post(`${BASE_URL}/videoCall/confirmCall`, {
    userId,
    influencerId,
    currency,
  });
  return response;
};

export const didInfluencerAcceptCallApi = async (callId) => {
  let response = await axios.get(
    `${BASE_URL}/videoCall/didInfluencerAcceptCall/${callId}`
  );
  return response;
};

// user transaction api

export const userTransactionApi = async (userId, pageValue) => {
  setAuthToken();
  let response = await axios.get(
    `${BASE_URL}/get-orders/${userId}/${pageValue}/10`
  );
  return response;
};

// share api

export const sharedPostApi = async (data) => {
  let response = await axios.post(`${BASE_URL}/posts/share`, data);
  return response;
};
