import axios from "axios";
import { BASE_URL } from "../Utils/GlobalVariable";

export const userSignInSignUpAPi = async (userData) => {
  const response = await axios.post(`${BASE_URL}/doUserLoginOrSignup`, {
    ...userData,
    userType: "user",
  });
  return response;
};

export const getSubscriptionStatusApi = async (userId, influencerId) => {
  const response = await axios.post(
    `${BASE_URL}/subscriptions/getSubscribedStatus`,
    {
      userId: userId,
      influencerId: influencerId,
    }
  );

  return response;
};

export const addSuggestionsAPi = async (influencerId, sendMsg) => {
  let response = await axios.post(
    `${BASE_URL}/add-suggestion/${influencerId}`,
    sendMsg
  );
  return response;
};

export const getPurchasedPostCountApi = async (userId, influencerId) => {
  const count = await axios.get(
    `${BASE_URL}/get-purchased-post-count/${userId}/${influencerId}`
  );
  return count;
};

export const getInfluencerProfileApi = async (username) => {
  const response = await axios.get(`${BASE_URL}/influencer/${username}`);
  return response;
};

export const fetchCreateLiveEventsForUserApi = async (influencerId) => {
  const response = await axios.get(
    `${BASE_URL}/influencerId/${influencerId}/fetch-events`
  );
  return response;
};

export const chatPurchasedStatusApi = async (influencerId, userId) => {
  const response = await axios.get(
    `${BASE_URL}/chat/${influencerId}/${userId}`
  );
  return response;
};

export const initiateCallApi = async (payload) => {
  const response = await axios.post(`${BASE_URL}/calls/initiate`, payload);
  return response;
};
